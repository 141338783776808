import React, { Suspense, lazy } from 'react';
import LoadingScreen from './components/LoadingScreen';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const VideoClearanceForm = Loadable(
  lazy(() => import('./pages/video-clearance/VideoClearanceForm'))
);
const VideoClearanceFormSuccess = Loadable(
  lazy(() => import('./pages/video-clearance/Success'))
);
const SubmissionForms = Loadable(
  lazy(() => import('./pages/submission/Submission'))
);
const VideoSubmissionForms = Loadable(
  lazy(() => import('./pages/video-submission/VideoSubmissionForms'))
);
const VideoSubmissionFormsThanksPage = Loadable(
  lazy(() => import('./pages/video-submission/ThankYou'))
);
const Success = Loadable(lazy(() => import('./pages/submission/Success')));
const Invalid = Loadable(lazy(() => import('./pages/Invalid')));
const Assignee = Loadable(lazy(() => import('./pages/caa/Assignee')));
const Author = Loadable(lazy(() => import('./pages/caa/Author')));
const ThankYou = Loadable(lazy(() => import('./pages/caa/ThankYou')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));

const routes = [
  {
    path: 'ugc/:uuid',
    element: <SubmissionForms />
  },
  {
    path: 'ugc/:uuid/success',
    element: <Success />
  },
  {
    path: 'ugc/:uuid/invalid',
    element: <Invalid />
  },
  {
    path: 'ugc/:uuid/expired',
    element: <Invalid />
  },
  {
    path: 'clearance/:uuid',
    element: <VideoClearanceForm />
  },
  {
    path: 'clearance/:uuid/success',
    element: <VideoClearanceFormSuccess />
  },
  {
    path: 'clearance/:uuid/invalid',
    element: <Invalid />
  },
  {
    path: 'clearance/:uuid/expired',
    element: <Invalid />
  },
  {
    path: 'video-submissions/:path',
    element: <VideoSubmissionForms />
  },
  {
    path: 'video-submissions/:path/success',
    element: <VideoSubmissionFormsThanksPage />
  },
  {
    path: 'caa/assignee/:uuid/invalid',
    element: <Invalid />
  },
  {
    path: 'caa/author/:uuid/invalid',
    element: <Invalid />
  },
  {
    path: 'caa/assignee/:uuid',
    element: <Assignee />
  },
  {
    path: 'caa/author/:uuid',
    element: <Author />
  },
  {
    path: 'caa/author/:uuid/thank-you',
    element: <ThankYou />
  },
  {
    path: 'caa/assignee/:uuid/thank-you',
    element: <ThankYou />
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
