import * as Sentry from '@sentry/react';
import { recaptchaApiUrl, recaptchaScriptId } from '../constants';

export const loadRecaptchaScript = (apiKey) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(recaptchaScriptId)) {
      // Script already exists, resolve immediately
      resolve(window.grecaptcha);
    }

    const script = document.createElement('script');
    script.src = `${recaptchaApiUrl}?render=${apiKey}`;
    script.id = recaptchaScriptId;
    // Handle script loading
    script.onload = () => {
      if (window.grecaptcha) {
        resolve(window.grecaptcha);
      } else {
        const error = new Error(
          'reCAPTCHA script loaded but grecaptcha not available'
        );
        Sentry.captureException(error);
        reject(error);
      }
    };

    // Handle script errors
    script.onerror = (error) => {
      const errorMsg = `Failed to load reCAPTCHA script: ${error.message}`;
      Sentry.captureException(new Error(errorMsg));
      reject(new Error(errorMsg));
    };

    document.body.appendChild(script);
  });
};
